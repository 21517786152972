import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UniFrameworkModule } from '../../../framework/frameworkModule';
import { LayoutModule } from '../layout/layoutModule';
import { AppCommonModule } from '../common/appCommonModule';
import { UniInit } from './init';
import { Login } from './login/login-wrapper';
import { ConfirmInvite } from './confirmInvite/confirmInvite';
import { RegisterCompany } from './registerCompany/registerCompany';
import { PreLoginRegisterCompany } from './registerCompany/pre-login/pre-login';
import { PreLoginRegisterCompanyContract } from './registerCompany/pre-login/pre-login-contract/pre-login-contract';
import { Signup } from './signup/signup';
import { SignupBankid } from './signup/signup-bankid/signup-bankid';
import { UniPasswordGuide } from './password-guide';
import { NewDemo } from './registerCompany/new-demo/new-demo';
import { CompanySelector } from './login/company-selector/company-selector';
import { DnbLogin } from './login/dnb/dnb-login';
import { Sb1Login } from './login/sb1/sb1-login';
import { OnboardingModule } from '../onboarding/onboardingModule';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UniFrameworkModule,
        LayoutModule,
        AppCommonModule,
        OnboardingModule,
    ],
    declarations: [
        UniPasswordGuide,
        UniInit,
        Login,
        DnbLogin,
        Sb1Login,
        CompanySelector,
        ConfirmInvite,
        RegisterCompany,
        PreLoginRegisterCompany,
        PreLoginRegisterCompanyContract,
        Signup,
        SignupBankid,
        NewDemo,
    ],
})
export class InitModule {}
