<section class="content">
    <ng-container *ngIf="!isAuthenticated">
        <section class="login-background"></section>
        <section class="login-container">
            <section class="login-card" [ngStyle]="{ height: bankIdEnabled ? '350px' : 'auto' }">
                <h1>Velkommen tilbake</h1>

                <bankid-svg *ngIf="bankIdEnabled" r="76" g="28" b="123"></bankid-svg>

                <button (click)="login()" class="c2a login-button" [disabled]="isLoading">
                    Logg inn {{ bankIdEnabled ? 'med BankID' : '' }}
                </button>

                <a *ngIf="bankIdEnabled" class="partly-highlighted-link" (click)="authService.authenticateWithoutIdp()">
                    <span class="highlight">Klikk her</span>
                    om du ønsker å logge på med brukernavn og passord
                </a>
                <a class="partly-highlighted-link" [routerLink]="signupLink">
                    Har du ingen konto?
                    <span class="highlight">Registrer deg her</span>
                </a>
            </section>
        </section>
    </ng-container>

    <uni-company-selector *ngIf="isAuthenticated && companies?.length" [companies]="companies"> </uni-company-selector>
</section>
