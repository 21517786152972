import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AuthService } from './authService';
import { BrowserStorageService } from '@uni-framework/core/browserStorageService';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

export const headerInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const authService = inject(AuthService);
    const browserStorage = inject(BrowserStorageService);

    let headers = request.headers;

    if (!headers.get('Accept')) {
        headers = headers.set('Accept', 'application/json');
    }

    // Figure out if the request is going to an API that uses our auth headers
    const url = request.url;
    const isUniApi =
        url.startsWith('/api') ||
        url.startsWith('api/') ||
        url.startsWith('http://localhost') ||
        url.startsWith('https://localhost') ||
        url.startsWith(environment.BASE_URL_INTEGRATION) ||
        url.startsWith(environment.BASE_URL_FILES) ||
        url.startsWith(environment.UNI_JOB_SERVER_URL) ||
        url.startsWith(environment.ELSA_SERVER_URL) ||
        url.startsWith(environment.SIGNALR_PUSHHUB_URL) ||
        url.startsWith(environment.AGRI_BASE_URL);

    if (isUniApi) {
        if (!headers.get('Authorization') && authService.jwt) {
            headers = headers.set('Authorization', 'Bearer ' + authService.jwt);
        }

        if (!headers.get('CompanyKey') && authService.activeCompany) {
            headers = headers.set('CompanyKey', authService.activeCompany.Key);
        }

        if (!headers.get('year')) {
            let year = browserStorage.getItemFromCompany('activeFinancialYear');
            year = year || browserStorage.getItem('ActiveYear');
            if (year) {
                headers = headers.set('Year', year.Year + '');
            }
        }
    }

    return next(request.clone({ headers }));
};

export const errorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
    const router = inject(Router);

    return next(req).pipe(
        catchError((error: HttpErrorResponse) => {
            if (error.status === 403 && error.error?.detail === '2FA.MissingClaim') {
                const userSettingsRoute = '/settings/user?2fa-required=true';
                if (router.url !== userSettingsRoute) {
                    router.navigateByUrl(userSettingsRoute);
                }
            }

            return throwError(() => error);
        }),
    );
};
