@switch (theme) {
    @case (themes.EXT02) {
        <dnb-login [isLoading]="isLoading" [isAuthenticated]="isAuthenticated" [companies]="availableCompanies">
        </dnb-login>
    }
    <!-- @case (themes.SR) {
        <sb1-login
            [isLoading]="isLoading"
            [isAuthenticated]="isAuthenticated"
            [companies]="availableCompanies">
        </sb1-login>
    } -->
    @default {
        <section class="background" [ngStyle]="{ background: background, height: backgroundHeight }"></section>

        <section class="content">
            <section class="illustration-wrapper">
                <img *ngIf="illustration" [src]="illustration" />
            </section>

            <!-- Not authenticated -->
            <section class="login-container" *ngIf="!isAuthenticated">
                <!-- MOVE INTO SB1 LOGIN -->
                <section class="login-card" *ngIf="isSb1">
                    <p>SpareBank 1 Regnskap</p>
                    <button class="c2a login-button" (click)="authService.authenticate()">Logg inn</button>
                </section>
                <button class="c2a login-button" *ngIf="!isSb1" (click)="authService.authenticate()">Logg inn</button>
            </section>

            <!-- Authenticated -->
            <ng-container *ngIf="isAuthenticated">
                <section class="select-company-container" *ngIf="availableCompanies?.length">
                    <uni-company-selector [companies]="availableCompanies"> </uni-company-selector>
                </section>

                <!-- No companies (most themes will redirect to /register-company instead of showing this) -->
                <p *ngIf="!isLoading && !availableCompanies?.length">
                    Du har ikke tilgang til noen selskaper i systemet enda
                </p>
            </ng-container>
        </section>
    }
}
