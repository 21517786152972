import { Login } from './login/login-wrapper';
import { ConfirmInvite } from './confirmInvite/confirmInvite';
import { Signup } from './signup/signup';
import { SignupBankid } from './signup/signup-bankid/signup-bankid';
import { RegisterCompany } from './registerCompany/registerCompany';
import { PreLoginRegisterCompany } from './registerCompany/pre-login/pre-login';
import { PreLoginRegisterCompanyContract } from './registerCompany/pre-login/pre-login-contract/pre-login-contract';
import { Routes } from '@angular/router';

export const initRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
    },
    {
        path: 'login',
        component: Login,
    },
    {
        path: 'confirm/:guid',
        component: ConfirmInvite,
    },
    {
        path: 'sign-up',
        component: Signup,
    },
    {
        path: 'sign-up-bankid',
        component: SignupBankid,
    },
    {
        path: 'prelogin-register',
        component: PreLoginRegisterCompany,
    },
    {
        path: 'prelogin-contract',
        component: PreLoginRegisterCompanyContract,
    },
    {
        path: 'register-company',
        component: RegisterCompany,
    },
];
