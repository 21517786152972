import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommonModule } from '../common/appCommonModule';
import { UniSystemView } from './system/systemView';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';

@NgModule({
    imports: [CommonModule, AppCommonModule, UniFrameworkModule],
    declarations: [UniSystemView],
})
export class AboutModule {}
