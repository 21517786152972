import { enableProdMode } from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';
import { AppModule } from './app/appModule';
import { environment, setEnvironment } from './environments/environment';
import { setTheme } from './themes/theme';

// Setup design system library
import '@unimicro/design-system/components';
import { updateConfig } from '@unimicro/design-system/config';
updateConfig({ assetsPath: 'design-system-assets' });

declare global {
    interface Window {
        dnbDataLayer: any[];
        zESettings: any;
        clarity: any[];
        _paq: any[];
    }
}

async function loadConfigs() {
    const versionGuid = window['VERSION_GUID'];

    const [env, themeName] = await Promise.all([
        fetch(`/config/dist/env.json?v=${versionGuid}`).then((res) => res.json()),
        getThemeName(),
    ]);

    setEnvironment(env);

    await setTheme(themeName);

    if (environment.useProdMode) {
        enableProdMode();
    }
}

async function getThemeName() {
    try {
        const theme = await fetch(`/config/dist/theme/theme.json?v=${window['VERSION_GUID']}`).then((res) =>
            res.json(),
        );
        return theme.name || 'UE';
    } catch (e) {
        return 'UE';
    }
}

loadConfigs().then(() => {
    platformBrowser()
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));
});
