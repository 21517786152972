import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UniHttp } from '@uni-framework/core/http/http';
import { passwordValidator, passwordMatchValidator } from '../authValidators';
import { theme, THEMES } from 'src/themes/theme';
import { combineLatest, Observable, of } from 'rxjs';
import { AuthService } from '@app/authService';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { rigDate } from '@app/components/common/utils/rig-date';

@Component({
    selector: 'uni-confirm-invite',
    templateUrl: './confirmInvite.html',
})
export class ConfirmInvite {
    confirmInviteForm: UntypedFormGroup;
    errorMessage: string;
    validInvite: boolean = false;
    busy: boolean = false;

    private verificationCode: string;

    background = theme.init.background;
    illustration = theme.theme === THEMES.SR ? undefined : theme.init.illustration;

    constructor(
        private uniHttp: UniHttp,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        formBuilder: UntypedFormBuilder,
    ) {
        this.confirmInviteForm = formBuilder.group(
            {
                Name: new UntypedFormControl('', Validators.required),
                Password: new UntypedFormControl('', [Validators.required, passwordValidator]),
                ConfirmPassword: new UntypedFormControl('', [Validators.required, passwordValidator]),
            },
            {
                validator: passwordMatchValidator,
            },
        );

        combineLatest([this.route.params, this.route.queryParams])
            .pipe(
                map(([params, query]) => ({ ...params, ...query })),
                take(1),
                switchMap(({ guid, idphint }) => {
                    this.verificationCode = guid;

                    if (this.verificationCode) {
                        return this.validateInvite(idphint);
                    } else {
                        return of(false);
                    }
                }),
            )
            .subscribe({
                next: (success) => {
                    if (!success) {
                        this.invalidInvite();
                    }
                },
                error: () => {
                    this.invalidInvite();
                },
            });
    }

    validateInvite(idphint?: string): Observable<ConfirmInvite> {
        // Gets the full user-verification object to check that it's valid
        return this.uniHttp
            .asGET()
            .usingInitDomain()
            .withEndPoint('user-verification/' + this.verificationCode)
            .send()
            .pipe(map((response) => response.body))
            .pipe(
                tap((response) => {
                    if (
                        response.StatusCode === 0 &&
                        response.ExpirationDate &&
                        rigDate(response.ExpirationDate).isAfter(rigDate(), 'day')
                    ) {
                        this.validInvite = true;
                        this.errorMessage = '';

                        if (idphint) {
                            this.authService.authenticateNewInvite(idphint, this.verificationCode, response.Email);
                        }
                    } else {
                        this.errorMessage =
                            'Invitasjonen har utgått eller er ikke gyldig. ' +
                            'Vennligst be administrator invitere deg på nytt.';
                        this.confirmInviteForm.disable();
                    }
                }),
            );
    }

    submitUser() {
        this.busy = true;
        this.confirmInviteForm.disable();

        this.uniHttp
            .asPUT()
            .usingInitDomain()
            .withEndPoint('user-verification/' + this.verificationCode + '/')
            .withHeader('Content-Type', 'application/json')
            .withBody(this.confirmInviteForm.value)
            .send({ action: 'confirm-invite' })
            .pipe(map((response) => response.body))
            .subscribe(
                () => {
                    this.busy = false;
                    this.router.navigateByUrl('/login');
                    this.confirmInviteForm.enable();
                },
                () => {
                    this.busy = false;
                    this.confirmInviteForm.enable();
                    this.errorMessage = 'Noe gikk galt ved oppretting av bruker, vennligst prøv igjen.';
                },
            );
    }

    private invalidInvite() {
        this.validInvite = false;
        this.errorMessage = 'Invitasjonen er ikke gyldig. Vennligst be administrator invitere deg på nytt.';
        this.confirmInviteForm.disable();
    }
}
