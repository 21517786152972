<h1>Velg produktpakke</h1>
<section class="wrapper" *ngIf="!busy">
    <section class="contract-types">
        <section class="contract-type-grid">
            <contract-type-card
                *ngFor="let contractType of contractTypes"
                [contractType]="contractType"
                [recommended]="contractType.Name === 'Plus'"
                (selected)="onContractTypeSelected(contractType.ContractType)"
            >
            </contract-type-card>
        </section>

        <contract-types-comparison [useExt02SignupTheme]="usingExt02Theme"></contract-types-comparison>
    </section>
</section>
