import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorService } from './services/common/errorService';

@Injectable()
export class UniAngularErrorHandler implements ErrorHandler {
    constructor(@Inject(ErrorService) private errorService: ErrorService) {}

    public handleError(err: any) {
        if (this.isSoftrigApiError(err.url) || !environment.useProdMode) {
            this.errorService.handle(err);
        } else {
            this.errorService.logger.logError(err);
            console.error(err);
        }
    }

    private isSoftrigApiError(err) {
        if (err?.url && typeof err.url === 'string') {
            return err.url.replace(window.location.origin, '').startsWith('/api/');
        }

        return false;
    }
}
