<section class="background" [ngStyle]="{ background: background, height: backgroundHeight }"></section>

<section class="content">
    <section class="illustration-wrapper">
        <img *ngIf="illustration" [src]="illustration" />
    </section>

    <article class="authentication-component">
        <h1>{{ headerText }}</h1>

        <small class="alert error" *ngIf="errorMessage">
            {{ errorMessage }}
        </small>

        <!-- This should be it's own component. -->
        <ng-container *ngIf="isExt02Env">
            <section class="signup-step" *ngIf="!confirmationCode">
                <section class="init-subtitle" *ngIf="step1Successful && !alreadyCompleted">
                    En e-post med mer informasjon er sendt til {{ step1Form.controls['Email'].value }}. <br />Sjekk
                    gjerne søppelpostkassen dersom du ikke har mottatt noe i løpet av kort tid.
                </section>

                <section class="init-subtitle" *ngIf="step1Successful && alreadyCompleted">
                    En bruker med denne e-postadressen finnes i systemet fra før.
                    <a class="init-link" [routerLink]="['/init/login']"> Gå til innlogging </a>
                </section>

                <ng-container *ngIf="!step1Successful">
                    <section *ngIf="!errorMessage && step1Subtitle" class="init-subtitle">
                        {{ step1Subtitle }}
                    </section>

                    <form [formGroup]="step1Form">
                        <label class="uni-label">
                            <span>Navn</span>
                            <input type="text" name="name" formControlName="DisplayName" />
                        </label>

                        <label class="uni-label">
                            <span>E-post</span>
                            <input type="email" formControlName="Email" />
                        </label>

                        <label class="uni-label">
                            <span>Telefon</span>
                            <input type="tel" formControlName="PhoneNumber" />
                        </label>
                    </form>

                    <section class="bankid-agreement" *ngIf="isExt02Env && companyType !== 'demo'">
                        <section
                            [@shake]="shakeAlert"
                            (@shake.done)="shakeAlert = false"
                            *ngIf="showHasToAgreePrompt && !hasAgreedToCustomerAgreement"
                            class="alert info"
                        >
                            <i class="material-icons ng-star-inserted"> info_outline </i>
                            Du må godta våre vilkår for å kunne registrere deg
                        </section>

                        <section>Ved å fortsette godtar jeg;</section>

                        <rig-checkbox [(ngModel)]="hasAgreedToCustomerAgreement">
                            Ved å fortsette godtar jeg DNB Regnskap sin
                            <a (click)="$event.preventDefault(); openAgreement()">lisensavtale</a> og bekrefter at jeg
                            har lest DNB sin
                            <a (click)="$event.preventDefault(); openDnbAgreement()">personvernerklæring</a>. Avtalen
                            vil automatisk gå over til en betalbar tjeneste ved utløp av den kostnadsfrie perioden.

                            <uni-tooltip-deprecated
                                [type]="'info'"
                                [text]="
                                    'DNB Regnskap kan benyttes vederlagsfritt i den avtalte kostnadsfrie perioden.
                                Deretter påløper det abonnements- og transaksjonskostnader i henhold til egen prisliste.
                                Etter den kostnadsfrie perioden blir kunden automatisk trukket månedlig på den kontoen kunden
                                har oppgitt som belastningskonto i nettbanken, eller motta en faktura.'
                                "
                            >
                            </uni-tooltip-deprecated>
                        </rig-checkbox>
                    </section>

                    <section class="agreement-text" *ngIf="agreeementText">
                        {{ agreeementText }}
                    </section>

                    <button
                        class="c2a"
                        (click)="submitStep1Form()"
                        [attr.aria-busy]="busy"
                        [disabled]="busy || !step1Form?.valid"
                    >
                        Registrer
                    </button>

                    <a [routerLink]="['/init/login']" class="email-signup">
                        <p>
                            Har du allerede en bruker?
                            <span class="highlight-link"> Logg inn </span>
                        </p>
                    </a>

                    <ng-container *ngIf="supportsBankid">
                        <div class="separator"></div>

                        <a class="email-signup" (click)="goToBankIdSignUp()">
                            <p>
                                <span class="highlight-link">Klikk her</span>
                                om du ønsker å registrere deg med bankID
                            </p>
                            <bankid-svg r="76" g="28" b="123"></bankid-svg>
                        </a>
                    </ng-container>
                </ng-container>
            </section>
            <section class="signup-step" *ngIf="confirmationCode">
                <section class="init-subtitle" *ngIf="!step2Successful && !errorMessage && isProspect && !companyType">
                    Etter at du har satt passord får du mulighet til å velge om du vil ha et demoselskap eller om du vil
                    bruke ditt eget selskap. De første 30 dagene er uansett gratis.
                </section>

                <section class="init-subtitle" *ngIf="!step2Successful && !errorMessage">
                    Vennligst fyll ut ønsket passord for denne brukeren
                </section>

                <ng-container *ngIf="step2Successful && !errorMessage">
                    <section class="init-subtitle">
                        {{ step2SuccessfulText }}
                    </section>

                    <button class="c2a" style="margin: 1rem auto" (click)="login()">Logg inn</button>
                </ng-container>

                <ng-container *ngIf="!step2Successful && !invalidConfirmationCode && !userExists">
                    <form #step2="ngForm" [formGroup]="step2Form">
                        <label class="uni-label">
                            <span>Skriv inn ønsket passord</span>
                            <input type="password" formControlName="Password" autocomplete="new-password" />
                        </label>

                        <uni-password-guide *ngIf="step2Form?.controls" [passwordControl]="step2Form.controls.Password">
                        </uni-password-guide>

                        <label class="uni-label">
                            <span>Bekreft passord</span>
                            <input
                                type="password"
                                formControlName="ConfirmPassword"
                                (keydown.enter)="submitStep2Form()"
                            />
                            <small class="bad">{{
                                step2Form?.get('ConfirmPassword').errors?.passwordMatchValidator
                            }}</small>
                        </label>
                    </form>

                    <section class="button-row">
                        <button routerLink="/init/login" class="secondary">Avbryt</button>
                        <button
                            (click)="submitStep2Form()"
                            class="c2a"
                            [attr.aria-busy]="busy"
                            [disabled]="busy || !step2Form?.valid"
                        >
                            Fullfør registrering
                        </button>
                    </section>
                </ng-container>
            </section>

            <a
                class="init-link"
                *ngIf="invalidConfirmationCode && !userExists && confirmationCode"
                [routerLink]="['/init/sign-up']"
                (click)="backToRegisterButton()"
            >
                Tilbake til registrering
            </a>

            <a
                class="init-link"
                *ngIf="!invalidConfirmationCode && userExists && confirmationCode"
                [routerLink]="['/init/login']"
                (click)="userExists = false"
            >
                Gå til innlogging
            </a>
        </ng-container>
        <ng-container *ngIf="!isExt02Env">
            <!-- STEP 1 -->
            <section class="signup-step" *ngIf="!confirmationCode">
                <section class="init-subtitle" *ngIf="step1Successful && !alreadyCompleted">
                    En e-post med mer informasjon er sendt til {{ step1Form.controls['Email'].value }}. <br />Sjekk
                    gjerne søppelpostkassen dersom du ikke har mottatt noe i løpet av kort tid.
                </section>

                <section class="init-subtitle" *ngIf="step1Successful && alreadyCompleted">
                    En bruker med denne e-postadressen finnes i systemet fra før.
                    <a class="init-link" [routerLink]="['/init/login']"> Gå til innlogging </a>
                </section>

                <ng-container *ngIf="!step1Successful">
                    <section *ngIf="!errorMessage && step1Subtitle" class="init-subtitle">
                        {{ step1Subtitle }}
                    </section>

                    <form [formGroup]="step1Form">
                        <label class="uni-label">
                            <span>Ditt navn</span>
                            <input type="text" formControlName="DisplayName" />
                        </label>

                        <label class="uni-label">
                            <span>E-post</span>
                            <input type="email" formControlName="Email" />
                        </label>

                        <label class="uni-label">
                            <span>Telefon</span>
                            <input type="tel" formControlName="PhoneNumber" />
                        </label>
                    </form>

                    <section class="agreement-text">
                        {{ agreeementText }}
                    </section>

                    <button
                        class="c2a"
                        (click)="submitStep1Form()"
                        [attr.aria-busy]="busy"
                        [disabled]="busy || !step1Form?.valid"
                    >
                        Start registrering
                    </button>

                    <section class="login-link">
                        Har du allerede en bruker?
                        <a [routerLink]="['/init/login']">Logg inn</a>
                    </section>
                </ng-container>
            </section>

            <!-- STEP 2  -->
            <section class="signup-step" *ngIf="confirmationCode">
                <section class="init-subtitle" *ngIf="!step2Successful && !errorMessage && isProspect && isExt02Env">
                    Etter at du har satt passord får du mulighet til å velge om du vil ha et demoselskap eller om du vil
                    bruke ditt eget selskap. De første 30 dagene er uansett gratis.
                </section>

                <section class="init-subtitle" *ngIf="!step2Successful && !errorMessage">
                    Vennligst fyll ut ønsket passord for denne brukeren
                </section>

                <ng-container *ngIf="step2Successful && !errorMessage">
                    <section class="init-subtitle">
                        {{ step2SuccessfulText }}
                    </section>

                    <button class="c2a" style="margin: 1rem auto" (click)="login()">Logg inn</button>
                </ng-container>

                <ng-container *ngIf="!step2Successful && !invalidConfirmationCode && !userExists">
                    <form #step2="ngForm" [formGroup]="step2Form">
                        <label class="uni-label">
                            <span>Skriv inn ønsket passord</span>
                            <input type="password" formControlName="Password" autocomplete="new-password" />
                        </label>

                        <uni-password-guide *ngIf="step2Form?.controls" [passwordControl]="step2Form.controls.Password">
                        </uni-password-guide>

                        <label class="uni-label">
                            <span>Bekreft passord</span>
                            <input
                                type="password"
                                formControlName="ConfirmPassword"
                                (keydown.enter)="submitStep2Form()"
                            />
                            <small class="bad">{{
                                step2Form?.get('ConfirmPassword').errors?.passwordMatchValidator
                            }}</small>
                        </label>
                    </form>

                    <section class="button-row">
                        <button routerLink="/init/sign-up" class="secondary">Avbryt</button>
                        <button
                            (click)="submitStep2Form()"
                            class="c2a"
                            [attr.aria-busy]="busy"
                            [disabled]="busy || !step2Form?.valid"
                        >
                            Fullfør registrering
                        </button>
                    </section>
                </ng-container>
            </section>

            <a
                class="init-link"
                *ngIf="invalidConfirmationCode"
                [routerLink]="['/init/sign-up']"
                (click)="backToRegisterButton()"
            >
                Tilbake til registrering
            </a>
        </ng-container>
    </article>
</section>
