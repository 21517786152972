<section class="wrapper" *ngIf="!busy">
    <section *ngFor="let option of registrationOptions" class="registration-card authentication-component">
        <h1>{{ option.header }}</h1>
        <h2 *ngIf="option.subHeader" class="sub-header">{{ option.subHeader }}</h2>

        <section class="content">
            <section *ngIf="option.priceTag" class="price-tag">{{ option.priceTag }}</section>
            <section *ngFor="let text of option.textSections" [innerHTML]="text"></section>
        </section>

        <section class="footer">
            <section class="footer-text" *ngIf="option.footerText">{{ option.footerText }}</section>

            <button
                *ngFor="let button of option.buttons"
                [ngClass]="button.class || 'secondary'"
                (click)="button.action()"
            >
                {{ button.label }}
            </button>
        </section>
    </section>
</section>
