import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './authService';
import { take, filter, switchMap, map } from 'rxjs/operators';
import { UniModalService } from '@uni-framework/uni-modal';
import { UserNotificationSettingsModal } from './components/layout/notifications/user-notification-settings/user-notification-settings-modal';
import { CompanyService } from './services/common/companyService';
import { THEMES, theme } from 'src/themes/theme';

@Injectable()
export class InitParamsRouteGuard {
    constructor(
        private authService: AuthService,
        private companyService: CompanyService,
        private modalService: UniModalService,
    ) {}

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (next.queryParamMap.has('openUserNotificationSettings')) {
            this.authService.token$
                .pipe(
                    filter((token) => !!token),
                    take(1),
                )
                .subscribe((token) => {
                    this.modalService.open(UserNotificationSettingsModal);
                });
        }

        const loadCompaniesAndLookForMatch = (key: string, orgnumber: string) => {
            return this.authService.token$.pipe(
                filter((token) => !!token),
                take(1),
                switchMap(() => {
                    return this.companyService.GetAll().pipe(
                        map((companies) => {
                            let newCompany;
                            if (orgnumber) {
                                newCompany =
                                    companies &&
                                    companies.find(
                                        (c) => c.OrganizationNumber?.toLowerCase() === orgnumber.toLowerCase(),
                                    );

                                if (!newCompany && theme.theme === THEMES.EIKA) {
                                    window.location.href = 'https://www.eikaregnskap.no/ikke-kunde';
                                    return;
                                }
                            } else {
                                newCompany =
                                    companies && companies.find((c) => c.Key?.toLowerCase() === key.toLowerCase());
                            }

                            const currentCompany = this.authService.activeCompany;
                            if (newCompany && newCompany.Key !== currentCompany?.Key) {
                                setTimeout(() => this.authService.setActiveCompany(newCompany, state.url));
                            }

                            return true;
                        }),
                    );
                }),
            );
        };

        // Check for companyKey or orgNumber in params and sessionStorage
        if (
            next.queryParamMap.has('companyKey') ||
            sessionStorage.getItem('companyKey') ||
            next.queryParamMap.has('selectCompanyByOrgNumber') ||
            sessionStorage.getItem('selectCompanyByOrgNumber')
        ) {
            const key = next.queryParamMap.get('companyKey') || sessionStorage.getItem('companyKey');
            sessionStorage.removeItem('companyKey');

            const orgnumber =
                next.queryParamMap.get('selectCompanyByOrgNumber') ||
                sessionStorage.getItem('selectCompanyByOrgNumber');
            sessionStorage.removeItem('selectCompanyByOrgNumber');

            return loadCompaniesAndLookForMatch(key, orgnumber);
        }

        return true;
    }
}
