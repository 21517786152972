<section class="background" [ngStyle]="{ background: background }"></section>

<section class="content">
    <section class="illustration-wrapper">
        <img *ngIf="illustration" [src]="illustration" />
    </section>

    <article class="authentication-component" style="margin-bottom: auto">
        <h1>Registrer bruker</h1>
        <small class="alert error" *ngIf="errorMessage">{{ errorMessage }}</small>

        @if (validInvite) {
            <form #f="ngForm" [formGroup]="confirmInviteForm" (ngSubmit)="submitUser()">
                <label class="uni-label">
                    <span>Fullt navn</span>
                    <input type="text" uniAutoFocus formControlName="Name" autocomplete="name" />
                </label>

                <label class="uni-label">
                    <span>Passord</span>
                    <input type="password" formControlName="Password" autocomplete="new-password" />
                </label>

                <label class="uni-label">
                    <span>Bekreft passord</span>
                    <input type="password" formControlName="ConfirmPassword" autocomplete="new-password" />
                </label>

                <uni-password-guide
                    *ngIf="confirmInviteForm?.controls"
                    [passwordControl]="confirmInviteForm.controls.Password"
                >
                </uni-password-guide>

                <button
                    type="submit"
                    class="c2a init-submit-button"
                    [attr.aria-busy]="busy"
                    [disabled]="busy || !f.form.valid || !validInvite"
                >
                    Registrer bruker
                </button>
            </form>
        } @else if (!validInvite && !errorMessage) {
            <mat-spinner class="c2a" style="margin: 3rem auto"></mat-spinner>
        }
        <a *ngIf="validInvite || errorMessage" class="init-link" [routerLink]="['/init/login']"
            >Tilbake til innlogging</a
        >
    </article>
</section>
