import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface JobTicketResponse {
    Jobid: string;
    CompanyName: string;
    Status: string;
}

@Injectable({ providedIn: 'root' })
export class JobTicketService {
    constructor(private http: HttpClient) {}

    getJobTicketCompany(): Observable<JobTicketResponse[]> {
        return this.http.get<JobTicketResponse[]>('/api/jobticket/jobticket-company');
    }
}
