<uni-toolbar [config]="{ title: 'Systeminformasjon' }"></uni-toolbar>

<section class="application" *ngIf="!busy">
    <section class="flex-column gap-1 mb-4">
        <strong>Selskapsidentifikator:</strong>
        <div>
            {{ companyKey }}
            <i class="material-icons" (click)="copyCompanyKey()" title="Kopier til utklippstavle" role="button"
                >content_copy</i
            >
        </div>
    </section>

    <section class="flex-column gap-1 mb-4" *ngIf="releaseNumber">
        <strong>Systemversjon:</strong>
        <div>{{ releaseNumber }}</div>
    </section>

    <section class="flex-column gap-1 mb-4">
        <strong>Klient:</strong>
        <section class="info-grid">
            <span>Versjon:</span>
            <span>{{ frontend.fullVersion }} ({{ frontend.minVersion }})</span>

            <span>Dato:</span>
            <span>{{ frontend.buildDate }}</span>
        </section>
    </section>

    <section class="flex-column gap-1 mb-4" *ngIf="backend">
        <strong>API:</strong>
        <section class="info-grid">
            <span>Versjon:</span>
            <span>{{ backend.fullVersion }} ({{ backend.minVersion }})</span>

            <span>Dato:</span>
            <span>{{ backend.buildDate }}</span>
        </section>
    </section>
</section>
