<section role="dialog" class="uni-modal">
    <header>Regnskapsprogrammet er klart!</header>

    <article>
        <div>
            <uni-icon name="check" color="success" size="24" />
            <span>Regnskapsprogrammet er nå aktivert</span>
        </div>

        <div>
            <uni-icon name="check" color="success" size="24" />
            <span>Du får transaksjoner i banken</span>
        </div>

        <div>
            <uni-icon name="circle-info" color="info" size="24" />
            <span>
                <span class="fw-medium">Betaling fra regnskapssytemet settes opp av en rådgiver.</span>
                Det kan ta noen virkedager.
            </span>
        </div>
    </article>

    <footer class="center">
        <button class="c2a" (click)="onClose.emit()">Den er god</button>
    </footer>
</section>
