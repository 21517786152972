import { Component, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UniHttp } from '@uni-framework/core/http/http';
import { AuthService } from '@app/authService';
import { theme, THEMES } from 'src/themes/theme';
import { ElsaAgreement } from '@app/models';
import { animate, style, transition, trigger } from '@angular/animations';
import { forkJoin, Subscription } from 'rxjs';
import { ElsaAgreementService } from '@app/services/elsa/elsaAgreementService';
import { useOnboardingFlow2 } from '@app/components/common/utils/utils';
import { CelebrusService } from '@app/services/analytics-services/celebrus-service';

@Component({
    selector: 'uni-signup-bankid',
    templateUrl: './signup-bankid.html',
    styleUrls: ['./signup-bankid.sass'],
    animations: [
        trigger('shake', [
            transition('false => true', [
                animate('0.1s', style({ transform: 'rotate(1deg)' })),
                animate('0.1s', style({ transform: 'rotate(-1deg)' })),
                animate('0.1s', style({ transform: 'rotate(1deg)' })),
                animate('0.1s', style({ transform: 'rotate(0)' })),
            ]),
        ]),
    ],
})
export class SignupBankid {
    @HostBinding('class.ext02-signup') isDnb = theme.theme === THEMES.EXT02;
    @HostBinding('class.sb1-signup') isSb1 = theme.theme === THEMES.SR;
    @HostBinding('class.eika-signup') isEika = theme.theme === THEMES.EIKA;

    routeSubscription: Subscription;

    confirmationCode: string;
    busy: boolean;

    headerText = theme.theme === THEMES.SR ? 'Bekreft identiteten din med BankID' : 'Opprett bruker';
    appName = theme.appName;

    errorMessage: string;

    invalidConfirmationCode: boolean;
    userExists: boolean;
    showHasToAgreePrompt: boolean;

    background = theme.init.background;
    backgroundHeight = theme.init.signup_background_height;
    illustration = theme.theme === THEMES.SR ? undefined : theme.init.illustration;

    isProspect = false;
    email: string;
    hasAgreedToCustomerAgreement = false;
    customerAgreement: ElsaAgreement;
    gdprAgreement: ElsaAgreement;
    busyAgreement: boolean;
    companyType: string;
    contractType: string;
    orgNumber: string;
    campaignCode: string;
    bankidIdp: string;

    bankidClicks: number = 0;
    shakeAlert: boolean = false;

    flow = 1;

    constructor(
        public authService: AuthService,
        private http: UniHttp,
        private route: ActivatedRoute,
        private router: Router,
        private elsaAgreementService: ElsaAgreementService,
        private celebrusService: CelebrusService,
    ) {
        if (!this.routeSubscription) {
            this.routeSubscription = this.route.queryParams.subscribe((params) => {
                this.errorMessage = undefined;
                this.companyType = params['companyType'];
                this.contractType = params['contractType'];
                this.email = params['email'];
                this.orgNumber = params['orgno'];
                this.campaignCode = params['campaignCode'];
                this.bankidIdp = params['bankidIdp'];
                if (useOnboardingFlow2(+params['flow'])) {
                    this.flow = 2;
                }

                this.setHeaderText();

                // Called after we have fetched agreements
                const initFn = () => {
                    if (params['code']) {
                        this.headerText = this.flow === 2 ? this.headerText : 'Velg passord';
                        this.confirmationCode = params['code'];
                        this.validateConfirmationCode(this.confirmationCode);
                        this.isProspect = !!params['isprospect'];

                        if (this.isProspect || this.invalidConfirmationCode) {
                            this.checkBankIdExist();
                        }

                        this.addAnalyticsEvent(1);
                    } else {
                        this.confirmationCode = null;
                        this.checkBankIdExist();
                        this.addAnalyticsEvent(this.companyType === 'company' ? 2 : 3);
                    }
                };

                this.busyAgreement = true;

                const agreementRequests = [
                    this.elsaAgreementService.getCustomerAgreementByContractType(+this.contractType),
                ];
                if (this.isSb1 || this.isEika) {
                    agreementRequests.push(this.elsaAgreementService.getByType('UserLicenseGdpr'));
                }

                forkJoin(agreementRequests).subscribe({
                    next: ([customerAgreement, gdprAgreement]) => {
                        this.customerAgreement = customerAgreement;
                        if (gdprAgreement) {
                            this.gdprAgreement = gdprAgreement;
                        }
                        this.busyAgreement = false;
                        initFn();
                    },
                    error: () => {
                        this.busyAgreement = false;
                        initFn();
                    },
                });
            });
        }
    }

    ngOnDestroy() {
        this.routeSubscription?.unsubscribe();
    }

    setHeaderText() {
        const isDemo = this.companyType === 'demo';
        switch (theme.theme) {
            case THEMES.SR:
                this.headerText = isDemo ? 'Prøv demoversjon' : 'Bekreft identiteten din med BankID';
                break;
            case THEMES.EIKA:
                this.headerText = isDemo ? 'Prøv demo' : 'Godta lisensavtale og personvernerklæring og opprett bruker';
                break;
            case THEMES.EXT02:
            default:
                this.headerText = isDemo ? 'Prøv demo' : 'Opprett bruker';
                break;
        }
    }

    checkBankIdExist() {
        this.authService.getClientIdpRestrictions().subscribe((list) => {
            if (this.isDnb) {
                this.bankidIdp = list.find((x) => x === 'bankiddnbsso') ?? list.find((x) => x === 'bankid');
                console.debug('check bankid ' + this.bankidIdp);
            } else if (this.isSb1) {
                this.bankidIdp = list.find((x) => x === 'sb1bankid');
            } else if (this.isEika) {
                this.bankidIdp = list.find((x) => x === 'eika');
            } else {
                this.bankidIdp = undefined;
            }

            if (!this.bankidIdp) {
                if (this.isEika) {
                    // eika doesn't have email signup afaik
                    console.log(`couldn't find bankid idp`);
                    this.router.navigateByUrl('/init/login');
                    return;
                }
                this.goToEmailSignup();
            }
        });
    }

    public validateConfirmationCode(code) {
        this.busy = true;
        this.http
            .asGET()
            .usingInitDomain()
            .withEndPoint(`validate-confirmation?code=${code}`)
            .send()
            .subscribe(
                () => {
                    this.invalidConfirmationCode = false;
                    this.busy = false;
                },
                (err) => {
                    // Try catch to avoid having to null check everything
                    try {
                        const errorBody = err.body;
                        this.userExists = errorBody.Messages[0].Message.toLowerCase().indexOf('user') >= 0;
                    } catch (e) {}
                    this.headerText = 'En feil oppstod';
                    if (this.userExists) {
                        this.errorMessage = 'Du er allerede registrert. Vennligst gå til innloggingssiden.';
                    } else {
                        this.errorMessage = 'Bekreftelseskoden er utløpt. Vennligst prøv å registrere deg igjen.';
                        this.invalidConfirmationCode = true;
                    }

                    this.busy = false;
                },
            );
    }

    goToBankIdSignUp() {
        if (!this.hasAgreedToCustomerAgreement && this.isDnb && this.companyType !== 'demo') {
            this.showHasToAgreePrompt = true;
            this.bankidClicks++;

            if (this.bankidClicks % 2 === 0) {
                this.shakeAlert = true;
            }
            return;
        }

        this.bankidClicks = 0;
        this.authService.signUpBankID(
            this.email ? this.email : null,
            this.isProspect,
            {
                type: this.companyType,
                contractType: this.contractType,
                orgno: this.orgNumber,
                campaignCode: this.campaignCode,
                isSignUp: 'true',
                ...(this.flow === 2 ? { flow: '2' } : null),
                ...(this.isProspect ? { isprospect: 'true' } : null),
            },
            this.bankidIdp,
        );
    }

    goToEmailSignup() {
        const query = this.getQueryString();
        this.router.navigateByUrl('/init/sign-up?' + query);
    }

    openAgreement() {
        window.open(this.customerAgreement.DownloadUrl, '_blank');
    }

    openGdprAgreement() {
        const url = this.isDnb
            ? 'https://dnb.no/om-oss/personvern.html'
            : this.gdprAgreement?.DownloadUrl || 'https://www.sparebank1.no/nb/sr-bank/om-oss/personvern.html';

        window.open(url, '_blank');
    }

    private getQueryString(preLogin: boolean = true): string {
        const query = new URLSearchParams();

        if (preLogin) {
            if (this.confirmationCode) {
                query.append('code', this.confirmationCode);
            }
            if (this.email) {
                query.append('email', this.email);
            }
            if (this.isProspect) {
                query.append('isprospect', `${this.isProspect}`);
            }
        }

        if (this.companyType) {
            query.append('companyType', this.companyType);
        }
        if (this.contractType) {
            query.append('contractType', this.contractType);
        }
        if (this.orgNumber) {
            query.append('orgno', this.orgNumber);
        }
        if (this.campaignCode) {
            query.append('campaignCode', this.campaignCode);
        }

        return query?.toString() ?? '';
    }

    private addAnalyticsEvent(event: number) {
        if (!this.isDnb) return;

        let eventObject;

        switch (event) {
            case 1:
                eventObject = this.celebrusService.getCelebrusObject('Signup post mail', 'Set password', 'start', '0');
                break;
            case 2:
                eventObject = this.celebrusService.getCelebrusObject(
                    'DNB Regnskap - Register Company',
                    'Register with BankID',
                    'other step',
                    '1',
                );
                break;
            case 3:
                eventObject = this.celebrusService.getCelebrusObject(
                    'DNB Regnskap - Demo',
                    'Register with BankID',
                    'start',
                    '0',
                );

                break;
        }

        this.celebrusService.useDataLayer('PageLoaded', {
            process: eventObject,
        });
    }
}
