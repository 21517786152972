<uni-toast-list></uni-toast-list>
<api-status></api-status>

<redirect-to-unimicro *ngIf="redirectToUnimicro"></redirect-to-unimicro>

<section *ngIf="isAuthenticated && !isOnInitRoute">
    <cms-slidein></cms-slidein>
</section>

<section *ngIf="isAuthenticated && !isOnInitRoute" class="chatboxes">
    <uni-chat-box
        *ngFor="let chatbox of chatBoxService.businessObjects | async"
        [businessObject]="chatbox"
    ></uni-chat-box>
</section>

<uni-navbar
    [deactivateFunctions]="isPendingApproval || licenseExpired || isMissingRoles || isStandardUserMissingProducts"
    class="no-print"
    *ngIf="isAuthenticated && !isOnInitRoute"
></uni-navbar>

@if (!redirectToUnimicro) {
    <support-chat />
}

<section id="page-container" *ngIf="!redirectToUnimicro">
    <uni-sidebar
        *ngIf="
            isAuthenticated &&
            !isOnInitRoute &&
            !isPendingApproval &&
            !licenseExpired &&
            !isMissingRoles &&
            !isStandardUserMissingProducts
        "
    ></uni-sidebar>

    <missing-products-banner
        *ngIf="isAuthenticated && isStandardUserMissingProducts && !licenseExpired && !isPendingApproval"
    ></missing-products-banner>

    @if (isPendingApproval && !licenseExpired) {
        <section class="locked-container">
            <section class="company-lock">
                @if (isEikaEnvironment) {
                    <h3>Mangler informasjon</h3>
                    <span>
                        Takk for at du ønsker å benytte Eika Regnskap. Vi har dessverre ikke all nødvendig informasjon
                        om deg i våre systemer eller i Brønnøysundregistrene. Eika Økonomi AS vil derfor ta kontakt med
                        deg for å etablere kundeforholdet.
                    </span>
                } @else {
                    <h3>Avventer {{ isBankCustomer ? 'signering av dokumenter' : 'etablering av kundeforhold' }}</h3>
                    <span *ngIf="isBankCustomer">
                        For å ta i bruk fullverdig versjon av SpareBank 1 Regnskap må nødvendige avtaledokumenter være
                        signert. Du vil få informasjon om dokumentene på e-post og SMS, og du signerer med BankID. Etter
                        at dokumentene er signert kan det ta inntil 1 virkedag før systemet er tilgjengelig. <br />
                        <br />
                        Du vil motta en e-post fra oss når systemet er tilgjengelig.
                    </span>
                    <span *ngIf="!isBankCustomer">
                        For å ta i bruk fullverdig versjon av SpareBank 1 Regnskap må du være bedriftskunde i
                        {{ bankName }}. Dersom du allerede har fylt ut bli-kunde-skjemaet er saken din under behandling.
                        Nødvendige avtaledokumenter må signeres med BankID, og du får informasjon om dette på e-post og
                        SMS. Du vil også få beskjed når kundeforholdet er etablert og systemet tilgjengelig. <br />
                        <br />
                        Dersom du ikke allerede har fylt ut skjemaet, trykk på Bli kunde under.
                    </span>
                    <button *ngIf="!isBankCustomer" class="c2a" (click)="goToExternalSignup()">Bli kunde</button>
                }
            </section>
        </section>
    }

    <section class="locked-container" *ngIf="isAuthenticated && !isOnInitRoute && licenseExpired">
        <section class="license-lock">
            <span>
                Sluttdato på {{ expiredEntity }} har passert. Om du fortsatt ønsker å benytte systemet, må du kontakte
                <a [href]="supportPageUrl" target="_blank">support</a>.
            </span>
        </section>
    </section>

    <missing-roles-banner
        *ngIf="
            isMissingRoles && isAuthenticated && !isPendingApproval && !licenseExpired && !isStandardUserMissingProducts
        "
    ></missing-roles-banner>

    <main
        id="page-content-container"
        class="flex-column"
        *ngIf="!isPendingApproval && !licenseExpired && !isMissingRoles && !isStandardUserMissingProducts"
    >
        <uni-tabstrip *ngIf="isAuthenticated && !isOnInitRoute && hasActiveContract" class="flex-0-auto"></uni-tabstrip>

        <section id="app-router-outlet" class="router-outlet" cdkScrollable>
            <router-outlet></router-outlet>
        </section>
    </main>
</section>
