import { Component, EventEmitter } from '@angular/core';
import { IUniModal } from '../../interfaces';
import { DesignSystemModule } from '@uni-framework/ui/design-system/design-system.module';

@Component({
    selector: 'system-ready-modal',
    templateUrl: './system-ready-modal.html',
    styleUrls: ['./system-ready-modal.sass'],
    imports: [DesignSystemModule],
    standalone: true,
})
export class SystemReadyModal implements IUniModal {
    onClose = new EventEmitter();

    constructor() {}
}
