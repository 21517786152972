<section class="authentication-component">
    <h1>Velg selskap</h1>

    @if (busy) {
        <div class="flex gap-2 mt-3">
            <mat-spinner [diameter]="20"></mat-spinner>
            Laster selskapsdata..
        </div>
    } @else {
        <uni-select [config]="selectConfig" [items]="companies" (valueChange)="onCompanySelected($event)"> </uni-select>
    }
    @if (authService.loginErrorMessage) {
        <small class="cmp-error-msg"> {{ authService.loginErrorMessage }} </small>
    }
</section>
