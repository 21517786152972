<section class="init-header">
    <img class="logo" [src]="logoUrl" routerLink="/init/login" [class.sb-logo]="isSb1" />

    <a *ngIf="isAuthenticated" (click)="logout()">Logg ut</a>
    <a *ngIf="!isAuthenticated && showTryDemoLink" class="try-demo-link" (click)="handleDemoSelected()">
        {{ isDnb ? 'Jeg vil prøve demo først' : 'Prøv gratis' }}
    </a>
</section>

<section class="init-content">
    <router-outlet></router-outlet>
</section>

<section *ngIf="!isUnimicro" class="init-footer">Levert av Unimicro</section>
