import { Routes } from '@angular/router';
import { UniSystemView } from './system/systemView';

export const aboutRoutes: Routes = [
    {
        path: 'about',
        children: [
            {
                path: 'system',
                component: UniSystemView,
            },
        ],
    },
];
